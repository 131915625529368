import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { fetchAllAlarmDeviceEventsByEstateId, fetchAllDeviceEventsByDeviceId } from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const deviceEventsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const deviceEvents = createSlice({
  name: 'deviceEvents',
  initialState,
  reducers: {
    resetDeviceEvents: () => initialState,
    handleAddOneDeviceEvent: (state, { payload: { deviceEvent } }) => {
      deviceEventsAdapter.addOne(state, deviceEvent);
    },
    handleUpdateOneDeviceEvent: (state, { payload: { deviceEvent } }) => {
      deviceEventsAdapter.updateOne(state, { id: deviceEvent.id, changes: deviceEvent });
    },
    handleRemoveManyDeviceEvents: (state, { payload: { ids } }) => {
      deviceEventsAdapter.removeMany(state, ids);
    },
  },
  extraReducers: {
    [fetchAllDeviceEventsByDeviceId.fulfilled]: (state, { payload: { deviceId, normalized } }) => {
      // Remove all deviceEvents belonging to requested device and upsert new ones
      const ids = state.ids.filter(id => state.entities[id].deviceId === deviceId);
      deviceEventsAdapter.removeMany(state, ids);
      if (normalized.entities.deviceEvents) {
        deviceEventsAdapter.upsertMany(state, normalized.entities.deviceEvents);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllDeviceEventsByDeviceId.pending]: (state, action) => {
      // console.log('fetchAllDeviceEventsByDeviceId pending');
      state.loading = true;
    },
    [fetchAllDeviceEventsByDeviceId.rejected]: (state, action) => {
      // console.log('fetchAllDeviceEventsByDeviceId rejected');
      state.error = action.error;
      state.loading = false;
    },
    [fetchAllAlarmDeviceEventsByEstateId.fulfilled]: (state, { payload: { normalized } }) => {
      // Remove all deviceEvents
      deviceEventsAdapter.removeAll(state);
      if (normalized.entities.deviceEvents) {
        deviceEventsAdapter.upsertMany(state, normalized.entities.deviceEvents);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllAlarmDeviceEventsByEstateId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllAlarmDeviceEventsByEstateId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const {
  resetDeviceEvents,
  handleAddOneDeviceEvent,
  handleUpdateOneDeviceEvent,
  handleRemoveManyDeviceEvents,
} = deviceEvents.actions;
export const deviceEventsReducer = deviceEvents.reducer;
