import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { superGuardApi } from '../../../connectivity';

export const DEVICE_TYPES = ['contact', 'motion', 'siren', 'cube', 'environment'];

// Define normalizr entity schemas
const deviceEntity = new schema.Entity('devices');

// Thunks definitions
export const fetchAllDevices = createAsyncThunk('devices/fetchAllDevices', async () => {
  const filter = { order: 'type DESC', where: { type: { inq: DEVICE_TYPES } } };
  const data = await superGuardApi.get(`/devices`, { params: { filter } });
  const normalized = normalize(data, [deviceEntity]);
  // console.log(JSON.stringify(normalized, null, 2));
  return normalized;
});

export const fetchDevicesByEstateId = createAsyncThunk(
  'devices/fetchDevicesByEstateId',
  async ({ estateId }, thunkAPI) => {
    const filter = { order: 'type DESC', where: { estateId, type: { inq: DEVICE_TYPES } } };
    const data = await superGuardApi.get(`/devices`, { params: { filter } });
    const normalized = normalize(data, [deviceEntity]);
    // console.log(JSON.stringify(normalized, null, 2));
    return { estateId, normalized };
  }
);

export const fetchDeviceByTaekiaId = createAsyncThunk(
  'devices/fetchDeviceByTaekiaId',
  async ({ taekiaId }) => {
    const filter = { order: 'createdAt DESC', where: { taekiaId } };
    const data = await superGuardApi.get(`/devices`, { params: { filter } });
    const normalized = normalize(data, [deviceEntity]);
    return normalized;
  }
);

export const updateOneDevice = createAsyncThunk(
  'devices/updateOneDevice',
  async ({ device }, thunkAPI) => {
    const where = JSON.stringify({ id: device.id });
    await superGuardApi.patch(`/devices?where=${where}`, {
      name: device.name,
      location: device.location,
      proximityLevel: device.proximityLevel,
      paired: device.paired,
    });
    return device;
  }
);

export const removeManyDevices = createAsyncThunk(
  'devices/removeManyDevices',
  async ({ estateId, ids }, { getState }) => {
    const where = JSON.stringify({ id: { inq: ids } });
    await superGuardApi.delete(`/estates/${estateId}/devices?where=${where}`);
    return ids;
  }
);
