import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  fetchAllMembers,
  fetchAllMembersByEstateId,
  removeManyMembers,
  updateOneMember,
} from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const membersAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.circle - b.circle || a.nickname.localeCompare(b.nickname), // sort by ascending `circle` and `nickname`
});

const members = createSlice({
  name: 'members',
  initialState,
  reducers: {
    resetMembers: () => initialState,
    handleAddOneMember: (state, { payload: { member } }) => {
      membersAdapter.addOne(state, member);
    },
    handleUpdateOneMember: (state, { payload: { member } }) => {
      membersAdapter.updateOne(state, { id: member.id, changes: member });
    },
    handleRemoveManyMembers: (state, { payload: { ids } }) => {
      membersAdapter.removeMany(state, ids);
    },
  },
  extraReducers: {
    /* [fetchAllMembersByEstateId.fulfilled]: (state, { payload: { estateId, normalized } }) => {
      // Remove all members belonging to requested estate and upsert new ones
      const ids = state.ids.filter(id => state.entities[id].estateId === estateId);
      membersAdapter.removeMany(state, ids);
      if (normalized.entities.members) {
        membersAdapter.upsertMany(state, normalized.entities.members);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllMembersByEstateId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllMembersByEstateId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    }, */
    [fetchAllMembersByEstateId.fulfilled]: (state, action) => {
      if (action.payload.entities.members) {
        membersAdapter.setAll(state, action.payload.entities.members);
      } else {
        membersAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllMembersByEstateId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllMembersByEstateId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchAllMembers.fulfilled]: (state, action) => {
      if (action.payload.entities.members) {
        membersAdapter.setAll(state, action.payload.entities.members);
      } else {
        membersAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllMembers.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllMembers.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [updateOneMember.fulfilled]: (state, action) => {
      membersAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
      state.error = false;
      state.loading = false;
    },
    [updateOneMember.pending]: (state, action) => {
      state.loading = true;
    },
    [updateOneMember.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [removeManyMembers.fulfilled]: (state, action) => {
      membersAdapter.removeMany(state, action.payload);
      state.error = false;
      state.loading = false;
    },
    [removeManyMembers.pending]: (state, action) => {
      state.loading = true;
    },
    [removeManyMembers.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { resetMembers, handleAddOneMember, handleUpdateOneMember, handleRemoveManyMembers } =
  members.actions;
export const membersReducer = members.reducer;
