import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { devicesAdapter } from './slice';

const selectSelf = state => state.superGuard.devices;

export const {
  selectById: selectDeviceById,
  selectIds: selectDeviceIds,
  selectEntities: selectDeviceEntities,
  selectAll: selectAllDevices,
  selectTotal: selectTotalDevices,
} = devicesAdapter.getSelectors(selectSelf);

export const selectAllDevicesByEstateId = estateId =>
  createDraftSafeSelector(selectAllDevices, allDevices =>
    allDevices.filter(d => d.estateId === estateId)
  );

export const selectDeviceByTaekiaId = taekiaId =>
  createDraftSafeSelector(selectAllDevices, allDevices =>
    allDevices.find(d => d.taekiaId === taekiaId)
  );

export const selectLastDeviceEventByEstateId = estateId =>
  createDraftSafeSelector(selectAllDevicesByEstateId(estateId), devices => {
    if (!devices) {
      return undefined;
    }
    // Find the latest event within all devices events and belonging to a particular type
    const types = ['open', 'close', 'presence', 'absence'];
    let latestEvent;
    for (const device of devices) {
      if (device.lastEvents) {
        const events = Object.values(device.lastEvents);
        for (const event of events) {
          // Make sure the event belongs to allowed types
          if (types.includes(event.type)) {
            // Set the first event
            if (!latestEvent) {
              latestEvent = event;
              continue;
            }
            // Check if event is more recent
            if (new Date(event.createdAt) >= new Date(latestEvent.createdAt)) {
              latestEvent = event;
              continue;
            }
          }
        }
      }
    }
    return latestEvent;
  });
