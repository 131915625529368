import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { gatewayEventsAdapter } from './slice';

const selectSelf = state => state.superGuard.gatewayEvents;

export const {
  selectById: selectGatewayEventById,
  selectIds: selectGatewayEventIds,
  selectEntities: selectGatewayEventEntities,
  selectAll: selectAllGatewayEvents,
  selectTotal: selectTotalGatewayEvents,
} = gatewayEventsAdapter.getSelectors(selectSelf);

export const selectAllGatewayEventsByGatewayId = gatewayId =>
  createDraftSafeSelector(selectAllGatewayEvents, allGatewayEvents =>
    allGatewayEvents.filter(g => g.gatewayId === gatewayId)
  );
