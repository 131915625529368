import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { gatewaysAdapter } from './slice';

const selectSelf = state => state.superGuard.gateways;

export const {
  selectById: selectGatewayById,
  selectIds: selectGatewayIds,
  selectEntities: selectGatewayEntities,
  selectAll: selectAllGateways,
  selectTotal: selectTotalGateways,
} = gatewaysAdapter.getSelectors(selectSelf);

export const selectAllGatewaysByEstateId = estateId =>
  createDraftSafeSelector(selectAllGateways, allGateways =>
    allGateways.filter(g => g.estateId === estateId)
  );

export const selectGatewayByTaekiaId = taekiaId =>
  createDraftSafeSelector(selectAllGateways, allGateways =>
    allGateways.find(g => g.taekiaId === taekiaId)
  );
