// GLOBALS
export const SITE_DOMAIN = 'super-guard.taekia.tech';

export const APP_VERSION = process.env.NODE_ENV === 'production' ? 'v0.1' : 'dev';

export const API_VERSION = process.env.NODE_ENV === 'production' ? 'v0.1' : 'dev';

// CORE
export const AUTH_API_URL =
  process.env.NODE_ENV === 'production'
    ? `https://api.auth.taekia.tech/${API_VERSION}`
    : 'https://dev.api.auth.taekia.tech';

export const GUARD_API_URL =
  process.env.NODE_ENV === 'production'
    ? `https://api.guard.taekia.tech/${API_VERSION}`
    : 'https://dev.api.guard.taekia.tech';

export const SUPER_GUARD_API_URL =
  process.env.NODE_ENV === 'production'
    ? `https://api.guard.taekia.tech/${API_VERSION}`
    : 'https://dev.api.guard.taekia.tech';

export const MQTT_URL =
  process.env.NODE_ENV === 'production'
    ? 'wss://mqtt.taekia.tech:443/mqtt'
    : 'wss://dev.mqtt.taekia.tech:443/mqtt';

// SOCIALS
export const GOOGLE_API_KEY = 'AIzaSyDTd3ehaZtbdomHG8ul-kyW0nmEcB9bZSE';

export const PLAUSIBLE_URL = 'https://plausible.taekia.tech';
