import { createAsyncThunk } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

import { resetUsers } from '../auth';
import {
  resetDeviceEvents,
  resetDevices,
  resetEstates,
  resetGatewayEvents,
  resetGateways,
  resetMembers,
} from '../super-guard';

export const cleanGlobalState = createAsyncThunk(
  'global/cleanGlobalState',
  async (params, { dispatch }) => {
    batch(() => {
      // Reset all sensible reducers
      dispatch(resetDeviceEvents());
      dispatch(resetDevices());
      dispatch(resetEstates());
      dispatch(resetGatewayEvents());
      dispatch(resetGateways());
      dispatch(resetMembers());
      dispatch(resetUsers());
    });
  }
);
