import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { superGuardApi } from '../../../connectivity';

export const DEVICE_EVENT_TYPES = [
  'open',
  'close',
  'presence',
  'absence',
  'alarm-on',
  'alarm-off',
  'disarm',
  'temperature',
  'humidity',
  'pressure',
];

// Define normalizr entity schemas
const deviceEventEntity = new schema.Entity('deviceEvents');

// Thunks definitions
export const fetchAllDeviceEventsByDeviceId = createAsyncThunk(
  'deviceEvents/fetchAllDeviceEventsByDeviceId',
  async ({ estateId, deviceId }, { getState }) => {
    const filter = {
      order: 'createdAt DESC',
      limit: 50,
      where: { type: { inq: DEVICE_EVENT_TYPES } },
    };
    const data = await superGuardApi.get(`/estates/${estateId}/devices/${deviceId}/events`, {
      params: { filter },
    });
    const normalized = normalize(data, [deviceEventEntity]);
    // console.log(JSON.stringify(normalized, null, 2));
    return { deviceId, normalized };
  }
);

export const fetchAllAlarmDeviceEventsByEstateId = createAsyncThunk(
  'deviceEvents/fetchAllAlarmDeviceEventsByEstateId',
  async ({ estateId, alarmId }, thunkAPI) => {
    const filter = { order: 'createdAt DESC', limit: 50 };
    const data = await superGuardApi.get(`/estates/${estateId}/alarms/${alarmId}/device-events`, {
      params: { filter },
    });
    const normalized = normalize(data, [deviceEventEntity]);
    // console.log(JSON.stringify(normalized, null, 2));
    return { normalized };
  }
);
