import { configureStore } from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import createMigrate from 'redux-persist/es/createMigrate';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { combinedReducer } from './combined-reducer';

// import logger from 'redux-logger';

// const preloadedState = {
//   todos: [
//     {
//       text: 'Eat food',
//       completed: true,
//     },
//     {
//       text: 'Exercise',
//       completed: false,
//     },
//   ],
//   visibilityFilter: 'SHOW_COMPLETED',
// };

const migrations = {
  2: state => {
    return {
      ...state,
      auth: {
        ...state.auth,
        users: {
          ...state.auth.users,
          ids: [],
          entities: [],
        },
      },
    };
  },
};

const persistConfig = {
  key: 'root',
  storage,
  version: 2,
  migrate: createMigrate(migrations, { debug: true }),
  // stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, combinedReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }) /* .concat(logger) */,
  devTools: process.env.NODE_ENV !== 'production',
  // preloadedState,
});
