import { API_VERSION } from 'app/config';

import { client } from './client';

const debug = msg => {
  // eslint-disable-next-line no-console
  console.log('\n');
  // eslint-disable-next-line no-console
  console.log('mqtt(estates):', msg);
};

export const subscribeEstateById = ({ estateId, circle }) => {
  client.subscribe(
    `guard/${API_VERSION}/estates/${estateId}/circle/${circle}`,
    { qos: 0 },
    (err, granted) => {
      if (err) {
        debug(err);
      } else {
        debug(granted);
      }
    }
  );
};

export const unsubscribeEstateById = ({ estateId, circle }) => {
  client.unsubscribe(`guard/${API_VERSION}/estates/${estateId}/circle/${circle}`);
};

export const subscribeAllEstates = () => {
  client.subscribe(`guard/${API_VERSION}/estates/+/circle/0`, { qos: 0 }, (err, granted) => {
    if (err) {
      debug(err);
    } else {
      debug(granted);
    }
  });
};

export const unsubscribeAllEstates = () => {
  client.unsubscribe(`guard/${API_VERSION}/estates/+/circle/0`);
};
