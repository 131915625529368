import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { estatesAdapter } from './slice';

const selectSelf = state => state.superGuard.estates;

export const {
  selectById: selectEstateById,
  selectIds: selectEstateIds,
  selectEntities: selectEstateEntities,
  selectAll: selectAllEstates,
  selectTotal: selectTotalEstates,
} = estatesAdapter.getSelectors(selectSelf);

export const selectCurrentEstate = createDraftSafeSelector(
  selectSelf,
  ({ entities, currentEstateId }) => {
    return entities[currentEstateId];
  }
);

export const selectCurrentEstateId = createDraftSafeSelector(selectSelf, ({ currentEstateId }) => {
  return currentEstateId;
});
