import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { superGuardApi } from '../../../connectivity';

// Define normalizr entity schemas
const gatewayEntity = new schema.Entity('gatewayEvents');

// Thunks definitions
export const fetchAllGatewayEventsByGatewayId = createAsyncThunk(
  'gateway-events/fetchAllGatewayEventsByGatewayId',
  async ({ gatewayId }, thunkAPI) => {
    const filter = {
      order: 'createdAt DESC',
      where: { gatewayId },
      limit: 50,
    };
    const data = await superGuardApi.get(`/gateway-events`, { params: { filter } });
    const normalized = normalize(data, [gatewayEntity]);
    return { gatewayId, normalized };
  }
);
