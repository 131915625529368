import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { superGuardApi } from '../../../connectivity';

// Define normalizr entity schemas
const memberEntity = new schema.Entity('members');

// Thunks definitions
/* export const fetchAllMembersByEstateId = createAsyncThunk(
  'members/fetchAllMembersByEstateId',
  async ({ estateId }, { getState }) => {
    const filter = { order: ['circle ASC', 'createdAt ASC'] };
    let data = await superGuardApi.get(`/estates/${estateId}/members`, { params: { filter } });
    const normalized = normalize(data, [memberEntity]);
    return { estateId, normalized };
  }
); */

export const fetchAllMembersByEstateId = createAsyncThunk(
  'members/fetchAllMembersByEstateId',
  async ({ estateId }, { getState }) => {
    const filter = { where: { estateId }, order: ['circle ASC', 'createdAt ASC'] };
    const data = await superGuardApi.get(`/members`, { params: { filter } });
    const normalized = normalize(data, [memberEntity]);
    return normalized;
  }
);

export const fetchAllMembers = createAsyncThunk(
  'members/fetchAllMembers',
  async ({ userId }, { getState }) => {
    const filter = { order: ['circle ASC', 'createdAt ASC'] };
    const data = await superGuardApi.get(`/users/${userId}/members`, { params: { filter } });
    const normalized = normalize(data, [memberEntity]);
    return normalized;
  }
);

export const updateOneMember = createAsyncThunk(
  'members/updateOneMember',
  async ({ member }, thunkAPI) => {
    const where = JSON.stringify({ id: member.id });
    await superGuardApi.patch(`/estates/${member.estateId}/members?where=${where}`, {
      nickname: member.nickname,
      circle: member.circle,
    });
    return member;
  }
);

export const removeManyMembers = createAsyncThunk(
  'members/removeManyMembers',
  async ({ estateId, ids }, { getState }) => {
    const where = JSON.stringify({ id: { inq: ids } });
    await superGuardApi.delete(`/estates/${estateId}/members?where=${where}`);
    return ids;
  }
);
