import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { fetchAllGatewayEventsByGatewayId } from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const gatewayEventsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const gatewayEvents = createSlice({
  name: 'gatewayEvents',
  initialState,
  reducers: {
    resetGatewayEvents: () => initialState,
  },
  extraReducers: {
    [fetchAllGatewayEventsByGatewayId.fulfilled]: (
      state,
      { payload: { gatewayId, normalized } }
    ) => {
      // Remove all gatewayEvents belonging to requested gateway and upsert new ones
      const ids = state.ids.filter(id => state.entities[id].gatewayId === gatewayId);
      gatewayEventsAdapter.removeMany(state, ids);
      if (normalized.entities.gatewayEvents) {
        gatewayEventsAdapter.upsertMany(state, normalized.entities.gatewayEvents);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllGatewayEventsByGatewayId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllGatewayEventsByGatewayId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { resetGatewayEvents } = gatewayEvents.actions;
export const gatewayEventsReducer = gatewayEvents.reducer;
