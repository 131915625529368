import { alarmHistoriesAdapter } from './slice';

const selectSelf = state => state.superGuard.alarmHistories;

export const {
  selectById: selectAlarmHistoryById,
  selectIds: selectAlarmHistoryIds,
  selectEntities: selectAlarmHistoryEntities,
  selectAll: selectAllAlarmHistories,
  selectTotal: selectTotalAlarmHistories,
} = alarmHistoriesAdapter.getSelectors(selectSelf);
