import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  addOneEstate,
  fetchAllEstates,
  fetchEstateById,
  removeOneEstate,
  updateEstateAlarmActivated,
  updateEstateSirenActivated,
  updateOneEstate,
} from './thunks';

const initialState = {
  ids: [],
  entities: {},
  currentEstateId: undefined,
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const estatesAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.name.localeCompare(b.name), // sort by ascending `name`
});

const estates = createSlice({
  name: 'estates',
  initialState,
  reducers: {
    resetEstates: () => initialState,
    setCurrentEstateId: (state, action) => {
      state.currentEstateId = action.payload;
    },
    handleUpdateOneEstate: (state, { payload: { estate } }) => {
      estatesAdapter.updateOne(state, { id: estate.id, changes: estate });
    },
    handleUpdateOneEstateWithEstateEvent: (state, { payload: { estateEvent } }) => {
      switch (estateEvent.type) {
        case 'alarm-activated':
          estatesAdapter.updateOne(state, {
            id: state.currentEstateId,
            changes: { alarmActivated: true },
          });
          break;
        case 'alarm-deactivated':
          estatesAdapter.updateOne(state, {
            id: state.currentEstateId,
            changes: { alarmActivated: false },
          });
          break;
        case 'siren-activated':
          estatesAdapter.updateOne(state, {
            id: state.currentEstateId,
            changes: { sirenActivated: false },
          });
          break;
        case 'siren-deactivated':
          estatesAdapter.updateOne(state, {
            id: state.currentEstateId,
            changes: { sirenActivated: false },
          });
          break;
        default:
          break;
      }
    },
    handleUpdateOneEstateWithAlarmMemberEvent: (state, { payload: { alarmMemberEvent } }) => {
      switch (alarmMemberEvent.type) {
        case 'disarm':
          estatesAdapter.updateOne(state, {
            id: state.currentEstateId,
            changes: { alarmActivated: false },
          });
          break;
        default:
          break;
      }
    },
    handleUpdateOneEstateWithDeviceEvent: (state, { payload: { estateId, deviceEvent } }) => {
      switch (deviceEvent.type) {
        case 'disarm':
          estatesAdapter.updateOne(state, {
            id: estateId,
            changes: { alarmActivated: false },
          });
          break;
        default:
          break;
      }
    },
  },
  extraReducers: {
    [fetchAllEstates.fulfilled]: (state, action) => {
      if (action.payload.entities.estates) {
        // Note: cannot use upsertMany as estates can be removed by side effects (e.g. if manager removes guardian)
        estatesAdapter.setAll(state, action.payload.entities.estates);
      } else {
        estatesAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllEstates.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllEstates.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchEstateById.fulfilled]: (state, action) => {
      if (action.payload.entities.estates) {
        estatesAdapter.upsertMany(state, action.payload.entities.estates);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchEstateById.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchEstateById.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [addOneEstate.fulfilled]: (state, action) => {
      // Add estate to estate entities
      estatesAdapter.addOne(state, action.payload);
      // // If currentEstate is not defined, then set the current estate to the one newly created
      // if (!state.currentEstate) {
      //   state.currentEstate = action.payload;
      // }
      state.error = false;
      state.loading = false;
    },
    [addOneEstate.pending]: (state, action) => {
      state.loading = true;
    },
    [addOneEstate.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [updateOneEstate.fulfilled]: (state, action) => {
      estatesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
      state.error = false;
      state.loading = false;
    },
    [updateOneEstate.pending]: (state, action) => {
      state.loading = true;
    },
    [updateOneEstate.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [updateEstateAlarmActivated.fulfilled]: (state, action) => {
      estatesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
      state.error = false;
      state.loading = false;
    },
    [updateEstateAlarmActivated.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEstateAlarmActivated.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [updateEstateSirenActivated.fulfilled]: (state, action) => {
      estatesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
      state.error = false;
      state.loading = false;
    },
    [updateEstateSirenActivated.pending]: (state, action) => {
      state.loading = true;
    },
    [updateEstateSirenActivated.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [removeOneEstate.fulfilled]: (state, action) => {
      const estate = action.payload;
      estatesAdapter.removeOne(state, estate.id);
      // Update current estate
      if (estate.id === state.currentEstateId) {
        if (state.ids.length > 0) {
          state.currentEstateId = state.ids[0];
        } else {
          state.currentEstateId = undefined;
        }
      }
      state.error = false;
      state.loading = false;
    },
    [removeOneEstate.pending]: (state, action) => {
      state.loading = true;
    },
    [removeOneEstate.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const {
  resetEstates,
  setCurrentEstateId,
  handleUpdateOneEstate,
  handleUpdateOneEstateWithEstateEvent,
  handleUpdateOneEstateWithAlarmMemberEvent,
  handleUpdateOneEstateWithDeviceEvent,
} = estates.actions;
export const estatesReducer = estates.reducer;
