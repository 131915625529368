import { combineReducers } from 'redux';

import { usersReducer } from '../auth';
import {
  alarmHistoriesReducer,
  alarmsReducer,
  deviceEventsReducer,
  devicesReducer,
  estatesReducer,
  gatewayEventsReducer,
  gatewaysReducer,
  membersReducer,
} from '../super-guard';

export const combinedReducer = combineReducers({
  auth: combineReducers({
    users: usersReducer,
  }),
  superGuard: combineReducers({
    alarmHistories: alarmHistoriesReducer,
    alarms: alarmsReducer,
    deviceEvents: deviceEventsReducer,
    devices: devicesReducer,
    estates: estatesReducer,
    gatewayEvents: gatewayEventsReducer,
    gateways: gatewaysReducer,
    members: membersReducer,
  }),
});
