import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { membersAdapter } from './slice';

const selectSelf = state => state.superGuard.members;

export const {
  selectById: selectMemberById,
  selectIds: selectMemberIds,
  selectEntities: selectMemberEntities,
  selectAll: selectAllMembers,
  selectTotal: selectTotalMembers,
} = membersAdapter.getSelectors(selectSelf);

/* // Break circular dependency error
// See: https://github.com/reduxjs/reselect/issues/169
const selectCurrentEstateId = createDraftSafeSelector(
  state => state.estates,
  ({ currentEstateId }) => {
    return currentEstateId;
  }
); */

export const selectManagerMembers = createDraftSafeSelector(selectAllMembers, allMembers => {
  return allMembers.filter(m => m.circle === 0);
});

export const selectGuardianMembers = createDraftSafeSelector(selectAllMembers, allMembers => {
  return allMembers.filter(m => m.circle > 0);
});

export const selectAllMembersByEstateId = estateId =>
  createDraftSafeSelector(selectAllMembers, allMembers => {
    return allMembers.filter(m => m.estateId === estateId);
  });
