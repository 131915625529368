import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { superGuardApi } from '../../../connectivity';

// Define normalizr entity schemas
const gatewayEntity = new schema.Entity('gateways');

// Thunks definitions
export const fetchAllGateways = createAsyncThunk('gateways/fetchAllGateways', async () => {
  const filter = { order: 'createdAt DESC' };
  const data = await superGuardApi.get(`/gateways`, { params: { filter } });
  const normalized = normalize(data, [gatewayEntity]);
  return normalized;
});

export const fetchAllGatewaysByEstateId = createAsyncThunk(
  'gateways/fetchAllGatewaysByEstateId',
  async ({ estateId }) => {
    const filter = { where: { estateId }, order: 'createdAt DESC' };
    const data = await superGuardApi.get(`/gateways`, { params: { filter } });
    const normalized = normalize(data, [gatewayEntity]);
    return { estateId, normalized };
  }
);

export const fetchGatewayByTaekiaId = createAsyncThunk(
  'gateways/fetchGatewayByTaekiaId',
  async ({ taekiaId }) => {
    const filter = { order: 'createdAt DESC', where: { taekiaId } };
    const data = await superGuardApi.get(`/gateways`, { params: { filter } });
    const normalized = normalize(data, [gatewayEntity]);
    return normalized;
  }
);

export const updateOneGateway = createAsyncThunk(
  'gateways/updateOneGateway',
  async ({ gateway }, thunkAPI) => {
    await superGuardApi.patch(`/gateways/${gateway.id}`, {
      // name: gateway.name,
      permitJoin: gateway.permitJoin,
      // estateId: gateway.estateId,
    });
    return gateway;
  }
);

export const removeManyGateways = createAsyncThunk(
  'gateways/removeManyGateways',
  async ({ estateId, ids }, { getState }) => {
    const where = JSON.stringify({ id: { inq: ids } });
    await superGuardApi.delete(`/gateways?where=${where}`);
    return ids;
  }
);
