import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { alarmsAdapter } from './slice';

const selectSelf = state => state.superGuard.alarms;

export const {
  selectById: selectAlarmById,
  selectIds: selectAlarmIds,
  selectEntities: selectAlarmEntities,
  selectAll: selectAllAlarms,
  selectTotal: selectTotalAlarms,
} = alarmsAdapter.getSelectors(selectSelf);

export const selectAlarmByEstateId = estateId =>
  createDraftSafeSelector([selectAllAlarms], allAlarms =>
    allAlarms.find(a => a.estateId === estateId)
  );

export const selectActiveAlarms = createDraftSafeSelector(selectAllAlarms, allAlarms => {
  const alarms = allAlarms.filter(a => a.ended === false);
  return alarms;
});

export const selectTotalActiveAlarms = createDraftSafeSelector(selectActiveAlarms, activeAlarms => {
  return activeAlarms.length;
});
