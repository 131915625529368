import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { superGuardApi } from '../../../connectivity';

// Define normalizr entity schemas
// const alarmEventEntity = new schema.Entity('events');
// const alarmEntity = new schema.Entity('alarms', { events: [alarmEventEntity] });
const alarmEntity = new schema.Entity('alarms');

// Thunks definitions
export const fetchAllAlarms = createAsyncThunk(
  'alarms/fetchAllAlarms',
  async (params, { getState }) => {
    const filter = {
      order: 'createdAt DESC',
      include: ['estate', 'triggerDevice', 'triggerDeviceEvent'],
    };
    const data = await superGuardApi.get(`/alarms`, { params: { filter } });
    const normalized = normalize(data, [alarmEntity]);
    return normalized;
  }
);

export const fetchAllAlarmsByEstateId = createAsyncThunk(
  'alarms/fetchAllAlarmsByEstateId',
  async ({ estateId }, { getState }) => {
    const filter = { where: { estateId }, include: ['triggerDevice', 'triggerDeviceEvent'] };
    let data = await superGuardApi.get('/alarms', { params: { filter } });
    const normalized = normalize(data, [alarmEntity]);
    return normalized;
  }
);
