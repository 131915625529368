import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  fetchAllDevices,
  fetchDeviceByTaekiaId,
  fetchDevicesByEstateId,
  removeManyDevices,
  updateOneDevice,
} from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const devicesAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.type.localeCompare(a.type) || b.createdAt.localeCompare(a.createdAt), // sort by descending `type` and `createdAt`
});

const devices = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    resetDevices: () => initialState,
    handleAddOneDevice: (state, { payload: { device } }) => {
      devicesAdapter.addOne(state, device);
    },
    handleUpdateOneDevice: (state, { payload: { device } }) => {
      devicesAdapter.updateOne(state, { id: device.id, changes: device });
    },
    handleRemoveManyDevices: (state, { payload: { ids } }) => {
      devicesAdapter.removeMany(state, ids);
    },
  },
  extraReducers: {
    [fetchAllDevices.fulfilled]: (state, action) => {
      if (action.payload.entities.devices) {
        devicesAdapter.setAll(state, action.payload.entities.devices);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllDevices.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllDevices.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchDevicesByEstateId.fulfilled]: (state, { payload: { estateId, normalized } }) => {
      // Remove all devices belonging to requested estate and upsert new ones
      const ids = state.ids.filter(id => state.entities[id].estateId === estateId);
      devicesAdapter.removeMany(state, ids);
      if (normalized.entities.devices) {
        devicesAdapter.upsertMany(state, normalized.entities.devices);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchDevicesByEstateId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchDevicesByEstateId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchDeviceByTaekiaId.fulfilled]: (state, action) => {
      if (action.payload.entities.devices) {
        devicesAdapter.upsertMany(state, action.payload.entities.devices);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchDeviceByTaekiaId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchDeviceByTaekiaId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [updateOneDevice.fulfilled]: (state, action) => {
      devicesAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
      state.error = false;
      state.loading = false;
    },
    [updateOneDevice.pending]: (state, action) => {
      state.loading = true;
    },
    [updateOneDevice.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [removeManyDevices.fulfilled]: (state, action) => {
      devicesAdapter.removeMany(state, action.payload);
      state.error = false;
      state.loading = false;
    },
    [removeManyDevices.pending]: (state, action) => {
      state.loading = true;
    },
    [removeManyDevices.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const {
  resetDevices,
  handleAddOneDevice,
  handleUpdateOneDevice,
  handleRemoveManyDevices,
  handleUpdateOneDeviceWithDeviceEvent,
} = devices.actions;
export const devicesReducer = devices.reducer;
