import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  fetchAllGateways,
  fetchAllGatewaysByEstateId,
  fetchGatewayByTaekiaId,
  removeManyGateways,
  updateOneGateway,
} from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const gatewaysAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const gateways = createSlice({
  name: 'gateways',
  initialState,
  reducers: {
    resetGateways: () => initialState,
    handleAddOneGateway: (state, { payload: { gateway } }) => {
      gatewaysAdapter.addOne(state, gateway);
    },
    handleUpdateOneGateway: (state, { payload: { gateway } }) => {
      gatewaysAdapter.updateOne(state, { id: gateway.id, changes: gateway });
    },
    handleRemoveManyGateways: (state, { payload: { ids } }) => {
      gatewaysAdapter.removeMany(state, ids);
    },
  },
  extraReducers: {
    [fetchAllGateways.fulfilled]: (state, action) => {
      if (action.payload.entities.gateways) {
        gatewaysAdapter.setAll(state, action.payload.entities.gateways);
      } else {
        gatewaysAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllGateways.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllGateways.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchAllGatewaysByEstateId.fulfilled]: (state, { payload: { estateId, normalized } }) => {
      // Remove all gateways belonging to requested estate and upsert new ones
      const ids = state.ids.filter(id => state.entities[id].estateId === estateId);
      gatewaysAdapter.removeMany(state, ids);
      if (normalized.entities.gateways) {
        gatewaysAdapter.upsertMany(state, normalized.entities.gateways);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllGatewaysByEstateId.pending]: (state, action) => {
      // console.log('fetchAllGatewaysByEstateId pending');
      state.loading = true;
    },
    [fetchAllGatewaysByEstateId.rejected]: (state, action) => {
      // console.log('fetchAllGatewaysByEstateId rejected');
      state.error = action.error;
      state.loading = false;
    },
    [fetchGatewayByTaekiaId.fulfilled]: (state, action) => {
      gatewaysAdapter.upsertMany(state, action.payload.entities.gateways);
      state.error = false;
      state.loading = false;
    },
    [fetchGatewayByTaekiaId.pending]: (state, action) => {
      // console.log('fetchAllGatewaysByEstateId pending');
      state.loading = true;
    },
    [fetchGatewayByTaekiaId.rejected]: (state, action) => {
      // console.log('fetchAllGatewaysByEstateId rejected');
      state.error = action.error;
      state.loading = false;
    },
    [updateOneGateway.fulfilled]: (state, action) => {
      gatewaysAdapter.updateOne(state, { id: action.payload.id, changes: action.payload });
      state.error = false;
      state.loading = false;
    },
    [updateOneGateway.pending]: (state, action) => {
      state.loading = true;
    },
    [updateOneGateway.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [removeManyGateways.fulfilled]: (state, action) => {
      gatewaysAdapter.removeMany(state, action.payload);
      state.error = false;
      state.loading = false;
    },
    [removeManyGateways.pending]: (state, action) => {
      state.loading = true;
    },
    [removeManyGateways.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const {
  resetGateways,
  handleAddOneGateway,
  handleUpdateOneGateway,
  handleRemoveManyGateways,
} = gateways.actions;
export const gatewaysReducer = gateways.reducer;
