import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { selectAllEstates, selectAllGateways } from '../super-guard';

export const selectAllGatewaysIncludeEstate = createDraftSafeSelector(
  selectAllGateways,
  selectAllEstates,
  (allGateways, allEstates) => {
    return allGateways.map(g => {
      const estate = allEstates.find(est => est.id === g.estateId);
      return { ...g, estate };
    });
  }
);
