import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { selectUserId } from '../auth';
import { selectEstateEntities, selectGuardianMembers, selectManagerMembers } from '../super-guard';

export const selectManagerEstates = createDraftSafeSelector(
  selectEstateEntities,
  selectManagerMembers,
  selectUserId,
  (estateEntities, managerMembers, currentUserId) => {
    const selfManagerMembers = managerMembers.filter(m => m.userId === currentUserId);
    const managerEstatesIds = selfManagerMembers.map(m => m.estateId);
    const managerEstates = [];
    for (const id of managerEstatesIds) {
      if (estateEntities[id]) {
        managerEstates.push(estateEntities[id]);
      }
    }
    return managerEstates;
  }
);

export const selectTotalManagerEstates = createDraftSafeSelector(
  selectManagerEstates,
  managerEstates => {
    return managerEstates.length;
  }
);

export const selectGuardianEstates = createDraftSafeSelector(
  selectEstateEntities,
  selectGuardianMembers,
  selectUserId,
  (estateEntities, guardianMembers, currentUserId) => {
    const selfGuardianMembers = guardianMembers.filter(m => m.userId === currentUserId);
    const guardianEstatesIds = selfGuardianMembers.map(m => m.estateId);
    const guardianEstates = [];
    for (const id of guardianEstatesIds) {
      if (estateEntities[id]) {
        guardianEstates.push(estateEntities[id]);
      }
    }
    return guardianEstates;
  }
);

export const selectTotalGuardianEstates = createDraftSafeSelector(
  selectGuardianEstates,
  guardianEstates => {
    return guardianEstates.length;
  }
);

export const selectAllEstatesByRole = createDraftSafeSelector(
  selectManagerEstates,
  selectGuardianEstates,
  (managerEstates, guardianEstates) => {
    // Create final data structure
    const formattedEstates = [];
    if (managerEstates.length) {
      formattedEstates.push({
        title: 'manager',
        data: managerEstates,
      });
    }
    if (guardianEstates.length) {
      formattedEstates.push({
        title: 'guardian',
        data: guardianEstates,
      });
    }
    return formattedEstates;
  }
);
