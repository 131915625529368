import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { authApi, pureAuthApi } from '../../../connectivity';

// Define normalizr entity schemas
const userEntity = new schema.Entity('users');

// Thunks definitions
export const fetchUser = createAsyncThunk('users/fetchUser', async thunkAPI => {
  const user = await authApi.get('/users/me');
  return user;
});

export const fetchAllUsers = createAsyncThunk('users/fetchAllUsers', async thunkAPI => {
  const filter = {
    include: [
      {
        relation: 'sessions',
        scope: { limit: 1, where: { appName: 'guard' }, order: 'seenAt DESC' },
      },
    ],
  };
  const data = await authApi.get('/users', { params: { filter } });
  const normalized = normalize(data, [userEntity]);
  return normalized;
});

export const requestLogin = createAsyncThunk(
  'users/requestLogin',
  async (
    { phone, verificationCode, platformName, platformVersion, appType, appName, appVersion },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const data = await pureAuthApi.post('/users/login/phone', {
        phone,
        verificationCode,
        platformName,
        platformVersion,
        appType,
        appName,
        appVersion,
      });
      return data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const requestVerificationCode = createAsyncThunk(
  'users/requestVerificationCode',
  async ({ phone }, { rejectWithValue }) => {
    try {
      await pureAuthApi.post('/users/verify/phone', { phone });
      return phone;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const requestSignup = createAsyncThunk(
  'users/requestSignup',
  async ({ firstName, lastName, email, phone, language }, { dispatch, rejectWithValue }) => {
    try {
      await pureAuthApi.post('/users/signup', { firstName, lastName, email, phone, language });
      await dispatch(requestVerificationCode({ phone }));
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

// NOTE: see `src/core/features/shared/global.thunks.js`
// we also reset the redux state when calling thunk `requestLogout` from UI
export const requestLogout = createAsyncThunk(
  'users/requestLogout',
  async (params, { dispatch, getState, rejectWithValue }) => {
    const accessToken = getState().auth.users.accessToken;
    const sessionId = getState().auth.users.sessionId;
    try {
      await pureAuthApi.post('/users/logout', { accessToken, sessionId });
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

// NOTE: see `src/core/api/authApi.js`
// we also reset the redux state when calling thunk `requestRefreshToken`
export const requestRefreshToken = createAsyncThunk(
  'users/requestRefreshToken',
  async (params, { dispatch, getState, rejectWithValue }) => {
    try {
      const accessToken = getState().auth.users.accessToken;
      const refreshToken = getState().auth.users.refreshToken;
      const data = await pureAuthApi.post('/users/refresh', { accessToken, refreshToken });
      return data;
    } catch (e) {
      throw rejectWithValue(e);
    }
  }
);

export const updateUser = createAsyncThunk('users/updateUser', async ({ user }, { dispatch }) => {
  await authApi.patch(`/users/${user.id}`, {
    firstName: user.firstName,
    lastName: user.lastName,
    language: user.language,
    phone: user.phone,
  });
  // The local JWT contains data, this data has to be updated so we need to refresh the token
  await dispatch(requestRefreshToken());
  return user;
});

export const updateUserSessionNotificationToken = createAsyncThunk(
  'users/updateUserSessionNotificationToken',
  async ({ userId, notificationToken }, { getState }) => {
    const sessionId = getState().auth.users.sessionId;
    await authApi.patch(`/users/${userId}/sessions/${sessionId}`, { notificationToken });
    return notificationToken;
  }
);

export const updateUserSession = createAsyncThunk(
  'users/updateUserSession',
  async ({ userId, appVersion }, { getState }) => {
    const sessionId = getState().auth.users.sessionId;
    await authApi.patch(`/users/${userId}/sessions/${sessionId}`, { appVersion });
  }
);
