import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { fetchAllAlarms, fetchAllAlarmsByEstateId } from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const alarmsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const alarms = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    resetAlarms: () => initialState,
    setShowAlarmDisarmModal: (state, action) => {
      state.showAlarmDisarmModal = action.payload;
    },
    setShowAlarmEndModal: (state, action) => {
      state.showAlarmEndModal = action.payload;
    },
    handleUpdateOneAlarm: (state, { payload: { alarm } }) => {
      alarmsAdapter.updateOne(state, { id: alarm.id, changes: alarm });
    },
    handleUpdateOneAlarmWithAlarmEvent: (state, { payload: { alarmEvent } }) => {
      switch (alarmEvent.type) {
        case 'timeout-ongoing':
        case 'timeout-intrusion':
          alarmsAdapter.updateOne(state, {
            id: alarmEvent.alarmId,
            changes: { ended: true },
          });
          break;
        default:
          break;
      }
    },
    handleUpdateOneAlarmWithAlarmMemberEvent: (state, { payload: { alarmMemberEvent } }) => {
      switch (alarmMemberEvent.type) {
        case 'disarm':
          alarmsAdapter.removeOne(state, alarmMemberEvent.alarmId);
          break;
        case 'mishandling':
        case 'no-intrusion':
        case 'end-intrusion':
          alarmsAdapter.updateOne(state, {
            id: alarmMemberEvent.alarmId,
            changes: { state: alarmMemberEvent.type, ended: true },
          });
          break;
        case 'intrusion':
          alarmsAdapter.updateOne(state, {
            id: alarmMemberEvent.alarmId,
            changes: { state: alarmMemberEvent.type },
          });
          break;
        /* case 'participate':
          alarmsAdapter.updateOne(state, { id: event.alarmId, changes: { nbParticipates: 2 } });
          break;
        case 'decline':
          alarmsAdapter.updateOne(state, { id: event.alarmId, changes: { nbDeclines: 2 } });
          break; */
        case 'request-circle-2':
          alarmsAdapter.updateOne(state, {
            id: alarmMemberEvent.alarmId,
            changes: { circle: 2 },
          });
          break;
        default:
          break;
      }
    },
    handleUpdateOneAlarmWithDeviceEvent: (state, { payload: { deviceEvent } }) => {
      switch (deviceEvent.type) {
        case 'disarm': {
          const id = state.ids.find(
            i => state.entities[i].estateId === deviceEvent?.device?.estateId
          );
          if (id) {
            alarmsAdapter.removeOne(state, id);
          }
          break;
        }
        default:
          break;
      }
    },
    handleAddOneAlarm: (state, { payload: { alarm } }) => {
      // Remove any old alarms concerned with the `estateId`
      clearOldAlarmsForEstate(state, alarm.estateId);
      alarmsAdapter.addOne(state, alarm);
    },
    handleRemoveManyAlarms: (state, { payload: { ids } }) => {
      alarmsAdapter.removeMany(state, ids);
    },
  },
  extraReducers: {
    [fetchAllAlarms.fulfilled]: (state, action) => {
      if (action.payload.entities.alarms) {
        alarmsAdapter.setAll(state, action.payload.entities.alarms);
      } else {
        alarmsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllAlarms.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllAlarms.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
    [fetchAllAlarmsByEstateId.fulfilled]: (state, action) => {
      if (action.payload.entities.alarms) {
        alarmsAdapter.setAll(state, action.payload.entities.alarms);
      } else {
        alarmsAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllAlarmsByEstateId.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllAlarmsByEstateId.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const {
  resetAlarms,
  setShowAlarmDisarmModal,
  setShowAlarmEndModal,
  handleUpdateOneAlarm,
  handleUpdateOneAlarmWithAlarmEvent,
  handleUpdateOneAlarmWithAlarmMemberEvent,
  handleUpdateOneAlarmWithDeviceEvent,
  handleAddOneAlarm,
  handleRemoveManyAlarms,
} = alarms.actions;
export const alarmsReducer = alarms.reducer;

const clearOldAlarmsForEstate = (state, estateId) => {
  const oldAlarmIds = state.ids.filter(i => state.entities[i].estateId === estateId);
  if (oldAlarmIds) {
    alarmsAdapter.removeMany(state, oldAlarmIds);
  }
};
