import { MQTT_URL } from 'app/config';
import { connect } from 'mqtt';

export let client;

const debug = msg => {
  // eslint-disable-next-line no-console
  console.log('\n');
  // eslint-disable-next-line no-console
  console.log('mqtt(client):', msg);
};

export const connectMqtt = (username, password, onMessage) => {
  if (client && client.connected) {
    return;
  }
  try {
    client = connect(MQTT_URL, { username, password });
  } catch (error) {
    debug(error);
  }

  client.on('connect', () => {
    debug('Connected');
  });

  client.on('error', error => {
    debug('Error:', error);
  });

  client.on('disconnect', cause => {
    debug('Disconnected:', cause);
  });

  client.on('message', (topic, message) => {
    debug(`Message from ${topic}`);
    // debug(message.toString());
    // Parse payload
    let payload;
    try {
      payload = JSON.parse(message.toString());
    } catch (e) {
      return e;
    }
    const broadcast = payload;
    debug(
      `model: ${broadcast.model} | action: ${broadcast.action} | data: ${JSON.stringify(
        broadcast.data
      )}`
    );
    onMessage(broadcast);
  });
};

export const disconnectMqtt = () => {
  client.end();
};
