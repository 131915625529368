import { addDays, format, formatDistanceToNow } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';

const dateIsToday = date => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const formatDate = (isoDate, lng) => {
  let dateFormat = '';
  switch (lng) {
    case 'fr-FR':
      dateFormat = 'dd/MM/yy';
      break;
    case 'en-US':
      dateFormat = 'M/d/yy';
      break;
    default:
      // defaults to international format
      dateFormat = 'MM/dd/yy'; // defaults to international format
  }
  return format(new Date(isoDate), dateFormat, {});
};

export const formatDateTime = (isoDate, lng) => {
  let hoursMinutesFormat = '';
  let daysMonthsFormat = '';
  switch (lng) {
    case 'fr-FR':
      hoursMinutesFormat = 'HH:mm';
      daysMonthsFormat = 'dd/MM';
      break;
    case 'en-US':
      hoursMinutesFormat = 'HH:mm';
      daysMonthsFormat = 'M/d';
      break;
    default:
      // defaults to international format
      hoursMinutesFormat = 'HH:mm';
      daysMonthsFormat = 'MM/dd'; // defaults to international format
  }
  return format(
    new Date(isoDate),
    dateIsToday(new Date(isoDate))
      ? hoursMinutesFormat
      : `${daysMonthsFormat} ${hoursMinutesFormat}`,
    {}
  );
};

export const formatBlogPostDate = (isoDate, lng) => {
  let hoursMinutesFormat = '';
  let daysMonthsFormat = '';
  switch (lng) {
    case 'fr-FR':
      hoursMinutesFormat = 'HH:mm';
      daysMonthsFormat = 'dd/MM/yy';
      break;
    case 'en-US':
      hoursMinutesFormat = 'HH:mm';
      daysMonthsFormat = 'M/d/yy';
      break;
    default:
      hoursMinutesFormat = 'HH:mm';
      daysMonthsFormat = 'M/d/yy';
  }
  return format(
    new Date(isoDate),
    dateIsToday(new Date(isoDate)) ? hoursMinutesFormat : `${daysMonthsFormat}`,
    {}
  );
};

export const formatDateTimeToNow = (isoDate, lng, justNowText) => {
  let locale = '';
  switch (lng) {
    case 'fr-FR':
      locale = fr;
      break;
    case 'en-US':
      locale = enUS;
      break;
    default:
      locale = enUS;
  }
  const date = new Date(isoDate);
  const fiveSeconds = 1000 * 5;
  const distance = Math.abs(Date.now() - date.getTime());
  if (distance < fiveSeconds) {
    return justNowText;
  }
  return formatDistanceToNow(date, { includeSeconds: true, addSuffix: true, locale });
};

export const formatDeliveryDate = (t, lng, delay) => {
  const today = new Date();
  const deliveryDate = addDays(today, delay);
  let locale = '';
  let dateFormat = '';
  switch (lng) {
    case 'fr-FR':
      locale = fr;
      dateFormat = 'EEEE dd MMMM';
      break;
    case 'en-US':
      locale = enUS;
      dateFormat = 'EEEE, MMMM dd';
      break;
    default:
      locale = enUS;
      dateFormat = 'EEEE, MMMM dd';
  }

  return format(deliveryDate, dateFormat, { locale: locale });
};
