import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';

import { superGuardApi } from '../../../connectivity';

// Define normalizr entity schemas
// const alarmHistoryEventEntity = new schema.Entity('events');
// const alarmHistoryEntity = new schema.Entity('alarmHistories', { events: [alarmEventEntity] });
const alarmHistoryEntity = new schema.Entity('alarmHistories');

// Thunks definitions
export const fetchAllAlarmHistories = createAsyncThunk(
  'alarmHistories/fetchAllAlarmHistories',
  async (params, { getState }) => {
    const filter = { order: 'createdAt DESC', limit: 20, include: ['estate'] };
    const data = await superGuardApi.get(`/alarm-histories`, { params: { filter } });
    const normalized = normalize(data, [alarmHistoryEntity]);
    return normalized;
  }
);
