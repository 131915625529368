import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { fetchAllAlarmHistories } from './thunks';

const initialState = {
  ids: [],
  entities: {},
  loading: false,
  error: false,
};

// Define redux-toolkit entity adapter
export const alarmHistoriesAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt), // sort by descending `createdAt`
});

const alarmHistories = createSlice({
  name: 'alarmHistories',
  initialState,
  reducers: {
    resetAlarmHistories: () => initialState,
  },
  extraReducers: {
    [fetchAllAlarmHistories.fulfilled]: (state, action) => {
      if (action.payload.entities.alarmHistories) {
        alarmHistoriesAdapter.setAll(state, action.payload.entities.alarmHistories);
      } else {
        alarmHistoriesAdapter.removeAll(state);
      }
      state.error = false;
      state.loading = false;
    },
    [fetchAllAlarmHistories.pending]: (state, action) => {
      state.loading = true;
    },
    [fetchAllAlarmHistories.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

export const { resetAlarmHistories } = alarmHistories.actions;
export const alarmHistoriesReducer = alarmHistories.reducer;
