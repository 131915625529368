import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  handleAddOneDevice,
  handleAddOneDeviceEvent,
  handleAddOneGateway,
  handleAddOneMember,
  handleRemoveManyDeviceEvents,
  handleRemoveManyDevices,
  handleRemoveManyGateways,
  handleRemoveManyMembers,
  handleUpdateOneDevice,
  handleUpdateOneDeviceEvent,
  handleUpdateOneEstate,
  handleUpdateOneEstateWithDeviceEvent,
  handleUpdateOneGateway,
  handleUpdateOneMember,
  selectDeviceById,
} from '../super-guard';

const handleMembersBroadcast = createAsyncThunk(
  'broadcasts/handleMembersBroadcast',
  async ({ data, action }, { dispatch }) => {
    switch (action) {
      case 'create': {
        for (const member of data) {
          dispatch(handleAddOneMember({ member }));
        }
        break;
      }
      case 'update': {
        for (const member of data) {
          dispatch(handleUpdateOneMember({ member }));
        }
        break;
      }
      case 'delete': {
        const ids = data;
        dispatch(handleRemoveManyMembers({ ids }));
        break;
      }
      default:
        break;
    }
  }
);

const handleDeviceEventsBroadcast = createAsyncThunk(
  'broadcasts/handleDeviceEventsBroadcast',
  async ({ data, action }, { dispatch, getState }) => {
    switch (action) {
      case 'create': {
        for (const deviceEvent of data) {
          dispatch(handleAddOneDeviceEvent({ deviceEvent }));
          const state = getState();
          const estateId = selectDeviceById(state, deviceEvent.deviceId)?.estateId;
          if (estateId) {
            dispatch(handleUpdateOneEstateWithDeviceEvent({ estateId, deviceEvent }));
          }
        }
        break;
      }
      case 'update': {
        for (const deviceEvent of data) {
          dispatch(handleUpdateOneDeviceEvent({ deviceEvent }));
        }
        break;
      }
      case 'delete': {
        const ids = data;
        dispatch(handleRemoveManyDeviceEvents({ ids }));
        break;
      }
      default:
        break;
    }
  }
);

const handleDevicesBroadcast = createAsyncThunk(
  'broadcasts/handleDevicesBroadcast',
  async ({ data, action }, { dispatch }) => {
    switch (action) {
      case 'create': {
        for (const device of data) {
          dispatch(handleAddOneDevice({ device }));
        }
        break;
      }
      case 'update': {
        for (const device of data) {
          dispatch(handleUpdateOneDevice({ device }));
        }
        break;
      }
      case 'delete': {
        const ids = data;
        dispatch(handleRemoveManyDevices({ ids }));
        break;
      }
      default:
        break;
    }
  }
);

const handleGatewaysBroadcast = createAsyncThunk(
  'broadcasts/handleGatewaysBroadcast',
  async ({ data, action }, { dispatch }) => {
    switch (action) {
      case 'create': {
        for (const gateway of data) {
          dispatch(handleAddOneGateway({ gateway }));
        }
        break;
      }
      case 'update': {
        for (const gateway of data) {
          dispatch(handleUpdateOneGateway({ gateway }));
        }
        break;
      }
      case 'delete': {
        const ids = data;
        dispatch(handleRemoveManyGateways({ ids }));
        break;
      }
      default:
        break;
    }
  }
);

const handleEstatesBroadcast = createAsyncThunk(
  'broadcasts/handleEstatesBroadcast',
  async ({ data, action }, { dispatch }) => {
    switch (action) {
      case 'update': {
        for (const estate of data) {
          dispatch(handleUpdateOneEstate({ estate }));
        }
        break;
      }
      default:
        break;
    }
  }
);

export const handleBroadcast = createAsyncThunk(
  'broadcasts/handleBroadcast',
  async ({ broadcast }, { dispatch }) => {
    const { model, data, action } = broadcast;
    switch (model) {
      case 'alarm':
        break;
      case 'alarm-event':
        break;
      case 'alarm-member-event':
        break;
      case 'invitation-received':
        break;
      case 'invitation-sent':
        break;
      case 'member':
        dispatch(handleMembersBroadcast({ data, action }));
        break;
      case 'device-event':
        dispatch(handleDeviceEventsBroadcast({ data, action }));
        break;
      case 'device':
        dispatch(handleDevicesBroadcast({ data, action }));
        break;
      /* case 'gateway-event':
        dispatch(handleGatewayEventsBroadcast({ data, action }));
        break; */
      case 'gateway':
        dispatch(handleGatewaysBroadcast({ data, action }));
        break;
      case 'estate-event':
        break;
      case 'estate':
        dispatch(handleEstatesBroadcast({ data, action }));
        break;

      default:
        break;
    }
    return broadcast;
  }
);
