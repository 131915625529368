import Alarms from './Alarms.json';
import common from './common.json';
import components from './components.json';
import DeviceDetails from './DeviceDetails.json';
import EstateDetails from './EstateDetails.json';
import Estates from './Estates.json';
import GatewayDetails from './GatewayDetails.json';
import Gateways from './Gateways.json';
import screens from './screens.json';
import Users from './Users.json';
import Welcome from './Welcome.json';

export const frFR = {
  ...Alarms,
  ...common,
  ...components,
  ...screens,
  ...DeviceDetails,
  ...EstateDetails,
  ...Estates,
  ...GatewayDetails,
  ...Gateways,
  ...Users,
  ...Welcome,
};
