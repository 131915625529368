import { createDraftSafeSelector } from '@reduxjs/toolkit';

import { deviceEventsAdapter } from './slice';

const selectSelf = state => state.superGuard.deviceEvents;

export const {
  selectById: selectDeviceEventById,
  selectIds: selectDeviceEventIds,
  selectEntities: selectDeviceEventEntities,
  selectAll: selectAllDeviceEvents,
  selectTotal: selectTotalDeviceEvents,
} = deviceEventsAdapter.getSelectors(selectSelf);

export const selectAllDeviceEventsByDeviceId = deviceId =>
  createDraftSafeSelector(selectAllDeviceEvents, allDeviceEvents =>
    allDeviceEvents.filter(e => e.deviceId === deviceId)
  );

export const selectAllDeviceEventsSince = date =>
  createDraftSafeSelector(selectAllDeviceEvents, allDeviceEvents =>
    allDeviceEvents.filter(e => new Date(e.createdAt) >= new Date(date))
  );
